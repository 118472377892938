import { React, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FileUpload } from 'primereact/fileupload';

import { classNames } from 'primereact/utils';
import { getFirestore, doc, app } from '../../firebaseConfig';
import { nanoid } from 'nanoid'
import { add, update, getTrip } from './module';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import PassengersGrid from "./PassengersGrid"
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

const Passengers = () => {
    //trip id passed in via route
    let { id } = useParams();
    const [trip, setTrip] = useState();
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Trips', url: `/trips` }]);
    const homeCrumb = { icon: 'pi pi-home', url: '/' }
    useEffect(() => {
        if (trip && trip.title) {
            setBreadcrumbs([...breadcrumbs, { label: trip.title }, { label: 'Passengers' }])
        }
    }, [trip])
    const passengerStatus = ['Not Checked In', 'Waiting to Join', 'Checked In', 'Left Trip']
    let emptyPassenger = {
        status: 'Not Checked In',
        firstName: '',
        surname: '',
        reference: '',
        tripID: id,
        passengerID: '',
        passportNumber: '',
        passportExpiry: '',
        hasLuggage: '',
        id: nanoid(10)
    };
    //Import CSV Dialog
    const [importCSV, setImportCSV] = useState(false);
    const openImport = () => {
        setImportCSV(true);
    }
    const hideImport = () => {
        setImportCSV(false);
    }
    const importError = (err) => {
        toast.current.show({ severity: 'error', summary: 'Error Uploading', detail: 'The upload of the Passenger CSV has failed', life: 3000 });
    }

    // Pull through associated Trip ID
    useEffect(() => {

        // declare the async data fetching function
        const fetchData = async () => {
            // get the data from the api
            const data = await getTrip(id)
            // set state with the result
            setTrip(data);
        }
        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, [id])

    // Local state
    const [dialog, setDialog] = useState(false);
    const [passenger, setPassenger] = useState(emptyPassenger);
    const [submitted, setSubmitted] = useState(false);

    const toast = useRef(null)

    const openNew = () => {
        setDialog(true);
    }
    const openEdit = (rowData) => {
        const fireData = { ...rowData }
        fireData.ref = rowData.ref //flag to indicate this is an update
        if (fireData.passportExpiry) {
            fireData.passportExpiry = fireData.passportExpiry.toDate()
        }
        setPassenger(fireData);
        setDialog(true);
    }
    const hideDialog = () => {
        setDialog(false);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _passenger = { ...passenger };
        _passenger[`${name}`] = val;
        setPassenger(_passenger);
    }

    const onBooleanChange = (e, name) => {
        let val = (e.target && e.target.checked) || false;
        let _passenger = { ...passenger };

        _passenger[`${name}`] = val;
        setPassenger(_passenger);
    }

    const onDateChange = (e, name) => {
        const val = e.value || new Date();
        let _passenger = { ...passenger };
        _passenger[`${name}`] = val;
        setPassenger(_passenger);
    }

    const savePassenger = async () => {
        // flag to display validation errors
        setSubmitted(true);
        // if no errors hide dialog
        setDialog(false);
        // Create copy of checkpoint
        const _passenger = { ...passenger, tripReference: trip.reference }

        if (passenger.ref) {
            await update(passenger.ref, _passenger)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Passenger Updated', life: 3000 });
        } else {
            console.log(_passenger)
            await add(_passenger)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Passenger Created', life: 3000 });
        }
        setPassenger(emptyPassenger);
    }

    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePassenger} />
        </>
    );

    return (

        <div className="grid ">
            <BreadCrumb model={breadcrumbs} home={homeCrumb} />
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {<PassengersGrid onEdit={openEdit} tripID={id} trip={trip} onNew={openNew} onImport={openImport}></PassengersGrid>}
                </div>
            </div>

            <Dialog visible={dialog} style={{ width: '450px' }} header={trip && "Passenger Details - " + trip.title} modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="firstName">First Name</label>
                        <InputText id="firstName" value={passenger.firstName} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !passenger.firstName })} />
                        {submitted && !passenger.firstName && <small className="p-invalid">First Name is required.</small>}
                    </div>

                    <div className="field col">
                        <label htmlFor="surname">Surname</label>
                        <InputText id="surname" value={passenger.surname} onChange={(e) => onInputChange(e, 'surname')} required className={classNames({ 'p-invalid': submitted && !passenger.surname })} />
                        {submitted && !passenger.surname && <small className="p-invalid">Surname is required.</small>}
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="reference">Reference</label>
                    <InputText id="reference" value={passenger.reference} onChange={(e) => onInputChange(e, 'reference')} className={classNames({ 'p-invalid': submitted && !passenger.reference })} />
                    {submitted && !passenger.reference && <small className="p-invalid">Reference is required.</small>}
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="passengerID">Passenger ID</label>
                        <InputText id="passengerID" value={passenger.passengerID} onChange={(e) => onInputChange(e, 'passengerID')} />
                    </div>
                    <div className="field col checkboxlabel">
                        <Checkbox inputId="noLuggage" onChange={(e) => onBooleanChange(e, 'noLuggage')} value={passenger.noLuggage} checked={passenger.noLuggage}></Checkbox>
                        <label htmlFor="noLuggage" className="p-checkbox-label checkboxlabel" >No Luggage</label>
                    </div>

                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="passportNumber">Passport Number</label>
                        <InputText id="passportNumber" value={passenger.passportNumber} onChange={(e) => onInputChange(e, 'passportNumber')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="passportExpiry">Passport Expiry</label>

                        <Calendar id="passportExpiry" dateFormat="dd/mm/yy" monthNavigator yearNavigator yearRange="2022:2040" value={passenger.passportExpiry} onChange={(e) => onDateChange(e, 'passportExpiry')}></Calendar>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="status">Status</label>
                        <Dropdown value={passenger.status} options={passengerStatus} onChange={(e) => onInputChange(e, 'status')} placeholder="Select a Status" />
                    </div>
                    <div className="field col">
                        <label htmlFor="tags">Tags</label>
                        <InputText id="tags" value={passenger.tagIDs} onChange={(e) => onInputChange(e, 'tagIDs')} />
                    </div>
                </div>

            </Dialog >
            <Dialog visible={importCSV} style={{ width: '450px' }} header="Import Passengers" modal onHide={hideImport}>
                <h3>Import CSV</h3>
                <p>Select Passenger CSV file</p>
                <FileUpload mode="basic" name="csv" url={`https://pdf.shinydesign.co.uk/import?tripID=${id}`} accept=".csv"
                    chooseLabel="Select CSV File" onUpload={hideImport} onError={importError} />

            </Dialog>
        </div >
    );
}



export default Passengers;
