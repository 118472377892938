import create from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';
export const useDeleteStore = create((set) => ({
    deleteDialog: false, //Display Delete Dialog
    deleteItem: {}, // WHich item to be deleted
    deleteObject: {}, // WHich item to be deleted
    deleteResult: false, // Was it deleted?
    deleteComponent: '',// Which component is calling the delete dialog
    toggleDeleteDialog: () => set((state) => ({ deleteDialog: !state.deleteDialog })),
    setDeleteItem: (deleteItem) =>
        set(() => ({
            deleteItem: deleteItem
        })),
    setDeleteObject: (deleteObject) =>
        set(() => ({
            deleteObject: deleteObject
        })),
    setDeleteComponent: (deleteComponent) =>
        set(() => ({
            deleteComponent: deleteComponent
        })),
    setDeleteResult: (deleteResult) =>
        set(() => ({
            deleteResult: deleteResult
        })),

}))

export const useTripsStore = create((set) => ({
    displayDialog: false, //Display Dialog
    editTrip: false, // Are we editing a trip or is a new trip?
    toggleDialog: () => set((state) => ({ displayDialog: !state.displayDialog })),
    setEditTrip: (editTrip) =>
        set(() => ({
            editTrip: editTrip
        })),

}))
export const useLuggageStore = create((set, get) => ({
    luggage: {}, //Specific Luggage
    imageURL: '', //Image URL
    setLuggage: (luggage) =>
        set(() => ({
            luggage: luggage
        })),
    setImageURL: (imageURL) => {
        set(() => ({
            imageURL: imageURL
        }))
    },

}))


if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('DeleteStore', useDeleteStore);
    mountStoreDevtool('LuggageStrore', useLuggageStore);
}
export default useDeleteStore