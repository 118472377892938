import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    getDoc,
    setDoc,
    doc,
    updateDoc,
    serverTimestamp,
    onSnapshot,
    orderBy,
    deleteDoc
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    setPersistence,
    browserSessionPersistence
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBd04OmGN4Pxh3kaK22NYsDLnJ5K8VhNcM",
    authDomain: "luggage-tracker-185ce.firebaseapp.com",
    projectId: "luggage-tracker-185ce",
    storageBucket: "luggage-tracker-185ce.appspot.com",
    messagingSenderId: "948148136421",
    appId: "1:948148136421:web:761e5d603d30eba2e6e70c",
    measurementId: "G-FGG40JME94"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
setPersistence(auth, browserSessionPersistence)

//const storage = getStorage(app);
const streamTrips = (snapshot, error) => {
    const itemsColRef = collection(db, 'trips')
    return onSnapshot(itemsColRef, snapshot, error);
};

const streamCheckpoints = (tripID, snapshot, error) => {
    console.log(`Trip ID for query is ${tripID}`)
    const q = query(collection(db, "checkpoints"), where("tripID", "==", tripID), orderBy("sequence", "asc"))
    return onSnapshot(q, snapshot, error);
};
const streamPassengers = (tripID, snapshot, error) => {
    console.log(`Trip ID for Passenger query is ${tripID}`)
    const q = query(collection(db, "passengers"), where("tripID", "==", tripID), orderBy("surname", "asc"))
    return onSnapshot(q, snapshot, error);
};
const streamLuggage = (passengerID, snapshot, error) => {
    console.log(`Passenger ID for Luggage query is ${passengerID}`)
    const q = query(collection(db, "luggage"), where("passengerID", "==", passengerID))
    return onSnapshot(q, snapshot, error);
};
const streamLuggageByTrip = (tripID, snapshot, error) => {
    console.log(`Trip ID for Luggage query is ${tripID}`)
    const q = query(collection(db, "luggage"), where("tripID", "==", tripID))
    return onSnapshot(q, snapshot, error);
};

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};


const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    app,
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    getFirestore,
    getDoc,
    getDocs,
    doc,
    addDoc,
    setDoc,
    updateDoc,
    serverTimestamp,
    collection,
    query,
    onSnapshot,
    streamTrips,
    streamCheckpoints,
    where,
    orderBy,
    deleteDoc,
    streamPassengers,
    streamLuggage,
    streamLuggageByTrip,
    storage
};


export function postToJSON(doc) {
    const data = doc.data();
    return {
        ...data,
        // Gotcha! firestore timestamp NOT serializable to JSON. Must convert to milliseconds
        departureDate: data?.departureDate.toMillis() || 0,
        returnDate: data?.returnDate.toMillis() || 0,
    };
}