import React from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';

const AppTopbar = (props) => {

    const history = useNavigate();

    const goDashboard = () => {
        history.push('/');
    };

    const menuButtonClassName = classNames('p-link', { 'menu-button-rotate': props.rotateMenuButton });
    return (
        <div className="topbar clearfix">
            <div className="topbar-left">
                <button className="p-link" onClick={goDashboard}>
                    <img alt="Logo" src="assets/layout/images/logo2.png" className="topbar-logo" />

                </button>

            </div>

            <div className="topbar-right">
                <button className={menuButtonClassName} id="menu-button" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-angle-left"></i>
                </button>


            </div>
        </div>
    );
}

export default AppTopbar;
