import { React, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { streamCheckpoints, streamLuggageByTrip, streamPassengers, deleteDoc } from '../firebaseConfig';
import useStore from '../store';
const DeleteConfirm = () => {
    // Use Store
    const deleteDialog = useStore((state) => state.deleteDialog);
    const deleteItem = useStore((state) => state.deleteItem);
    const toggleDeleteDialog = useStore((state) => state.toggleDeleteDialog);
    const deleteDocument = async (ref, data) => {
        try {
            await deleteDoc(ref);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }
    const setDeleteResult = useStore((state) => state.setDeleteResult);
    const setButtonResult = async (result) => {
        if (deleteItem.type === 'Trip' && result === true) {
            await deleteDocument(deleteItem.data.ref, deleteItem.data)
            streamCheckpoints(deleteItem.data.id,
                (querySnapshot) => {
                    const checkpoints = querySnapshot.docs.map(docSnapshot => {
                        const checkData = docSnapshot.data()
                        const ref = docSnapshot.ref
                        return { ref, ...checkData }
                    });
                    checkpoints.forEach(async (checkItem, index) => {
                        await deleteDocument(checkItem.ref, checkItem)
                    })
                },
                (error) => { console.log(error) }
            );
            streamLuggageByTrip(deleteItem.data.id,
                (querySnapshot) => {
                    const luggage = querySnapshot.docs.map(docSnapshot => {
                        const checkData = docSnapshot.data()
                        const ref = docSnapshot.ref
                        return { ref, ...checkData }
                    });
                    luggage.forEach(async (luggageItem, index) => {
                        await deleteDocument(luggageItem.ref, luggageItem)
                    })
                },
                (error) => { console.log(error) }
            );
            streamPassengers(deleteItem.data.id,
                (querySnapshot) => {
                    const passengers = querySnapshot.docs.map(docSnapshot => {
                        const checkData = docSnapshot.data()
                        const ref = docSnapshot.ref
                        return { ref, ...checkData }
                    });
                    passengers.forEach(async (passengerItem, index) => {
                        await deleteDocument(passengerItem.ref, passengerItem)
                    })
                },
                (error) => { console.log(error) }
            );

        }
        setDeleteResult(result)
        toggleDeleteDialog()
    }
    const dialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => { setButtonResult(false) }} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { setButtonResult(true) }} />
        </>
    );

    return (
        <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={dialogFooter} onHide={toggleDeleteDialog}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>Are you sure you want to delete <b>{deleteItem.type ? deleteItem.type : deleteItem}</b>?</span>
            </div>
        </Dialog>
    )
}

export default DeleteConfirm