
import { doc, db, serverTimestamp, setDoc, updateDoc, deleteDoc, getDoc } from '../../firebaseConfig';
const add = async (data) => {

    try {
        await setDoc(doc(db, "luggage", data.id), { created: serverTimestamp(), ...data });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}
const getTrip = async (id) => {
    try {
        let docSnap
        if (id) {
            console.log(id)
            const docRef = doc(db, "trips", id);
            docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                return docSnap.data();
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}

const update = async (ref, data) => {
    try {
        await updateDoc(ref, { ...data });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}
const deleteDocument = async (ref, data) => {
    try {
        await deleteDoc(ref);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}

export { add, update, deleteDocument, getTrip }