import { React, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';

import { streamLuggage } from '../../firebaseConfig';
import { deleteDocument } from './module';
import DeleteConfirm from '../DeleteConfirm';
import useDeleteStore from '../../store';
const LuggageGrid = ({ onEdit, passengerID, passenger, trip, onNew }) => {
    const [luggageList, setLuggageList] = useState([]);
    const [luggage, setLuggage] = useState({});
    // Delete Store
    const toggleDeleteDialog = useDeleteStore((state) => state.toggleDeleteDialog);
    const setDeleteItem = useDeleteStore((state) => state.setDeleteItem);
    const setDeleteComponent = useDeleteStore((state) => state.setDeleteComponent);
    const deleteResult = useDeleteStore((state) => state.deleteResult);
    const deleteComponent = useDeleteStore((state) => state.deleteComponent);

    // Stream Passenger Data for this Trip
    useEffect(() => {
        const unsubscribe = streamLuggage(passengerID,
            (querySnapshot) => {
                const luggageList = querySnapshot.docs.map(docSnapshot => {
                    const luggageData = docSnapshot.data()
                    const ref = docSnapshot.ref
                    return { ref, ...luggageData }
                });
                setLuggageList(luggageList);
            },
            (error) => { console.log(error) }
        );
        // need to unsubscribe to prevent memory leaks
        return unsubscribe;
    }, [setLuggageList, passengerID]);

    // Listen for a delete state change
    useEffect(() => {
        if (deleteComponent === 'luggage' && deleteResult) {
            deleteDocument(luggage.ref)
        }
    }, [deleteResult])


    const [globalFilter, setGlobalFilter] = useState(null);

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Luggage for {passenger && passenger.data().surname}</h5>
            {trip && <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3">
                    <span>{trip.title}</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Depart: {trip.departureDate && trip.departureDate.toDate().toLocaleDateString()}</span>
                </div>
                <div className="flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Return: {trip.returnDate && trip.returnDate.toDate().toLocaleDateString()}</span>
                </div>
            </div>
            }
            <div className="flex">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
                <Button label="New Luggage" icon="pi pi-plus" className="p-button-success p-mr-2 ml-2" onClick={() => onNew()} />
            </div>
        </div>
    );

    const deleteDoc = (luggage) => {
        // Local State
        setLuggage(luggage);
        // Delete State
        setDeleteComponent('luggage')
        setDeleteItem(`Luggage`);
        // Show Dialog
        toggleDeleteDialog()
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => { onEdit(rowData) }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => deleteDoc(rowData)} />
            </div>
        );
    }

    const imageTemplate = (rowData) => {
        return (

            <Image src={rowData.imageURL} width="200" height="200" alt="Image Text" />
        )
    }
    return <div>
        <DeleteConfirm />
        <DataTable value={luggageList}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} luggage"
            globalFilter={globalFilter} emptyMessage="No Luggage found." header={header} responsiveLayout="scroll">
            <Column field="status" header="Status" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="tagID" header="Tag ID" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            {/* <Column body={imageTemplate}></Column> */}
            <Column body={actionBodyTemplate}></Column>
        </DataTable>
    </div>;
}

export default LuggageGrid;