import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, } from 'firebase/auth';
import { auth } from "../firebaseConfig";
import { UserAuth } from '../context/AuthContext';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useAuthState } from "react-firebase-hooks/auth";

const Login = () => {


    const [email, setEmail] = useState("");
    const [authError, setAuthError] = useState();
    const [password, setPassword] = useState("");
    const { user, logout } = UserAuth();
    //   const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const logInWithEmailAndPassword = async (email, password) => {
        try {
            setAuthError();
            await signInWithEmailAndPassword(auth, email, password);

        } catch (err) {
            setAuthError(err.message);
            console.error(err);
        }
    };
    useEffect(() => {
        if (user) navigate("/trips");
    }, [user, navigate]);
    return (
        <div className="grid">
            <div className="col-6 col-offset-3">
                <div className="card p-fluid">
                    <div className="grid">
                        <div className="col-2" >
                            <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                        </div>
                        <div className="col-7">
                            <h2 className="welcome-text">Welcome</h2>
                            <span className="guest-sign-in">Sign in to Luggage Tracker</span>
                        </div>
                        <div className="col-12" style={{ textAlign: 'left' }}>
                            <label className="login-label">Email</label>
                            <div className="login-input">
                                <InputText placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-12" style={{ textAlign: 'left' }}>
                            <label className="login-label">Password</label>
                            <div className="login-input">
                                <Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-12 md:col-6 button-pane">
                            <Button label="Sign In" onClick={() => logInWithEmailAndPassword(email, password)} />
                        </div>
                        <div className="col-12 md:col-6 link-pane">
                            <button className="p-link">Forget Password?</button>
                        </div>

                        {authError &&
                            <div className="col-12 flex align-items-center justify-content-center " >
                                <Message severity="error" text="Username or Password is incorrect" className='flex p-4' />
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Login;
