import { React, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, } from "react-router-dom";
import { Column } from 'primereact/column';
import { streamTrips } from '../../firebaseConfig';
import { Tooltip } from 'primereact/tooltip';
import DeleteConfirm from '../DeleteConfirm';
import useDeleteStore from '../../store';
import { UserAuth } from '../../context/AuthContext';
import { streamPassengers } from '../../firebaseConfig';
import { db, collection, query, where, getDocs } from "../../firebaseConfig";
const TripDataGrid = ({ onEdit, onNew }) => {
    // Be able to open a new route for checkpoints
    let navigate = useNavigate();
    // react firebase hook to stream Trips
    const [trips, setTrips] = useState([]);
    const toggleDeleteDialog = useDeleteStore((state) => state.toggleDeleteDialog);
    const setDeleteItem = useDeleteStore((state) => state.setDeleteItem);
    const setDeleteComponent = useDeleteStore((state) => state.setDeleteComponent);
    const deleteResult = useDeleteStore((state) => state.deleteResult);
    const deleteComponent = useDeleteStore((state) => state.deleteComponent);
    const [trip, setTrip] = useState({});
    const { user } = UserAuth();


    useEffect(() => {
        const unsubscribe = streamTrips(

            (querySnapshot) => {
                const updatedTrips = querySnapshot.docs.map(docSnapshot => {
                    const checkData = docSnapshot.data()
                    // Check if user.email is in the access array - if not remove from the array
                    if (checkData.access) {
                        if (checkData.access.includes(user.email)) {
                            console.log(`user is in access array for ${checkData.reference}`)
                            const ref = docSnapshot.ref
                            return { ref, ...checkData }
                        }
                    }


                });
                console.log(updatedTrips.length)
                // remove undefined from array
                const filtered = updatedTrips.filter(function (el) {
                    return el != null;
                });

                setTrips(filtered);
            },
            (error) => { console.log(error) }
        );
        // need to unsubscribe to prevent memory leaks
        return unsubscribe;
    }, [setTrips]);


    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Trips</h5>
            <div className="flex">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
                <Button label="New Trip" icon="pi pi-plus" className="p-button-success p-mr-2 ml-2" onClick={() => onNew()} />

            </div>
        </div>
    );

    const departureDate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Departure Date</span>
                {rowData?.departureDate && (
                    rowData?.departureDate.toDate().toLocaleDateString("en-UK")
                )}
            </>
        );
    }
    const returnDate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Return Date</span>
                {rowData?.returnDate && (
                    rowData?.returnDate.toDate().toLocaleDateString("en-UK")
                )}
            </>
        );
    }
    const returnAccess = (rowData) => {
        if (rowData && rowData.access) {
            // convert array to string with comma seperator
            const access = rowData.access.join(', ');
            return access
        }
    }

    const deleteTrip = (trip) => {
        // Local State
        setTrip(trip);
        // Delete State
        setDeleteComponent('trips')
        setDeleteItem({ type: 'Trip', data: trip });
        // Show Dialog
        toggleDeleteDialog()
    }
    // change route and pass the ref to the edit page
    const viewLuggageTemplate = (ref) => {
        navigate("/luggage-checkpoints/" + ref);
    }
    // change route and pass the ref to the edit page
    const viewPassengers = (ref) => {
        navigate("/passengers/" + ref);
    }
    const viewBarcodes = async (ref) => {


        const q = query(collection(db, "passengers"), where("tripID", "==", ref.id));

        const querySnapshot = await getDocs(q);
        const names = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            names.push({ firstName: doc.data().firstName, surname: doc.data().surname, fullName: doc.data().firstName + " " + doc.data().surname })
        });
        // sort on surname
        names.sort((a, b) => (a.surname > b.surname) ? 1 : -1)


        const axios = require('axios');
        const data = {
            tripid: ref.id.toUpperCase(),
            passengers: names
        };
        try {
            const resp = await axios.post('https://pdf.shinydesign.co.uk/barcode/pdf', data, {
                responseType: 'blob',
            })

            // create temp download url
            const downloadUrl = window.URL.createObjectURL(resp.data)

            // open pdf file on new ta  b
            window.open(downloadUrl, '__blank')
            window.URL.revokeObjectURL(downloadUrl)

        } catch (e) {
            throw e
        }
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => { onEdit(rowData) }} tooltip="Edit Trip" />
                <Button icon="pi pi-check-circle" className="p-button-rounded p-button-warning mr-2" onClick={(e) => { viewLuggageTemplate(rowData.id) }} tooltip="Luggage Checkpoints" />
                <Button icon="pi pi-users" className="p-button-rounded p-button-primary mt-2 mr-2" onClick={(e) => { viewPassengers(rowData.id) }} tooltip="Passengers" />
                <Button icon="pi-flag-fill" className="p-button-rounded p-button-primary mt-2 mr-2" onClick={(e) => { viewBarcodes(rowData) }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => deleteTrip(rowData)} />
            </div>
        );
    }
    return <div>
        <DeleteConfirm />
        {<DataTable value={trips}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Trips"
            globalFilter={globalFilter} emptyMessage="No trips found." header={header} responsiveLayout="scroll">

            <Column field="title" header="Title" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="status" header="Status" sortable ></Column>
            <Column field="departureDate" header="Departure Date" sortable body={departureDate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
            <Column field="returnDate" header="Return Date" sortable body={returnDate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
            <Column field="reference" header="Reference" sortable  ></Column>
            <Column field="access" header="Access" body={returnAccess} sortable  ></Column>
            <Column body={actionBodyTemplate}></Column>
        </DataTable>}
    </div>;
}

export default TripDataGrid;