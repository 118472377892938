import { React, useState, useEffect } from 'react';
import { useNavigate, } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { streamPassengers } from '../../firebaseConfig';
import { update, deleteDocument } from './module';
import DeleteConfirm from '../DeleteConfirm';
import useDeleteStore from '../../store';


const PassengersGrid = ({ onEdit, tripID, onNew, trip, onImport }) => {

    let navigate = useNavigate();
    const [passengers, setPassengers] = useState([]);
    const [passenger, setPassenger] = useState({});
    const toggleDeleteDialog = useDeleteStore((state) => state.toggleDeleteDialog);
    const setDeleteItem = useDeleteStore((state) => state.setDeleteItem);
    const setDeleteComponent = useDeleteStore((state) => state.setDeleteComponent);
    const deleteResult = useDeleteStore((state) => state.deleteResult);
    const deleteComponent = useDeleteStore((state) => state.deleteComponent);

    // Stream Passenger Data for this Trip
    useEffect(() => {
        const unsubscribe = streamPassengers(tripID,
            (querySnapshot) => {
                const passengers = querySnapshot.docs.map(docSnapshot => {
                    const checkData = docSnapshot.data()
                    const ref = docSnapshot.ref
                    return { ref, ...checkData }
                });
                setPassengers(passengers);
            },
            (error) => { console.log(error) }
        );
        // need to unsubscribe to prevent memory leaks
        return unsubscribe;
    }, [setPassengers, tripID]);

    // Listen for a delete state change
    useEffect(() => {
        if (deleteComponent === 'passenger' && deleteResult) {
            deleteDocument(passenger.ref)
        }
    }, [deleteResult])
    const [globalFilter, setGlobalFilter] = useState(null);

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Passengers</h5>
            {trip && <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3">
                    <span>{trip.title}</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Depart: {trip.departureDate && trip.departureDate.toDate().toLocaleDateString()}</span>
                </div>
                <div className="flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Return: {trip.returnDate && trip.returnDate.toDate().toLocaleDateString()}</span>
                </div>
            </div>
            }
            <div className="flex">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
                <Button label="Export Passengers" icon="pi pi-download" className="p-button-primary p-mr-2 ml-2" onClick={() => exportCSV(passengers)} />

                <Button label="Import Passengers" icon="pi pi-file-excel" className="p-button-primary p-mr-2 ml-2" onClick={() => onImport()} />

                <Button label="New Passenger" icon="pi pi-plus" className="p-button-success p-mr-2 ml-2" onClick={() => onNew()} />

            </div>
        </div>
    );

    const openLuggage = (rowData) => {
        navigate("/luggage/" + tripID + "/" + rowData.id);
    }
    const deleteDoc = (passenger) => {
        // Local State
        setPassenger(passenger);
        // Delete State
        setDeleteComponent('passenger')
        setDeleteItem(`${passenger.firstName} - ${passenger.surname}`);
        // Show Dialog
        toggleDeleteDialog()
    }
    const exportCSV = async (passengerData) => {

        const axios = require('axios');

        try {
            const resp = await axios.get('https://pdf.shinydesign.co.uk/firebase/getpassengerluggage/' + tripID)


            // Create clean JSON array for download

            const passengers = resp.data.map((passenger) => {
                return (`${passenger.firstName},${passenger.surname},${passenger.status},${passenger.passport_barcode},${passenger.luggageCount}`)
            })
            //insert at the begining of the array the column headers
            passengers.unshift('First Name,Surname,Status,Passport Barcode,Luggage Count')

            downloadFile({
                data: passengers.join('\n'),
                fileName: 'passengers.csv',
                fileType: 'text/csv',
            })
        } catch (e) {
            throw e
        }

    }

    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => { onEdit(rowData) }} />
                <Button icon="pi pi-shopping-bag" className="p-button-rounded p-button-warning mt-2 mr-2" onClick={() => openLuggage(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => deleteDoc(rowData)} />

            </div>
        );
    }
    const noLuggageTemplate = (rowData) => {
        if (rowData && rowData.noLuggage === true) {
            return "Yes"
        }
    }

    const scannedTagsTemplate = (rowData) => {
        if (rowData && rowData.tagIDs) {
            return rowData.tagIDs.length
        }
    }
    const passportStatusTemplate = (rowData) => {
        if (rowData && rowData.passport_status === true) {
            return "IN"
        }
        if (rowData && rowData.passport_status === false) {
            return "OUT"
        }
        if (rowData && rowData.passport_status === undefined) {
            return "NOT SEEN"
        }
    }

    return <div>
        <DeleteConfirm />
        <DataTable value={passengers}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} passengers"
            globalFilter={globalFilter} emptyMessage="No Passengers found." header={header} responsiveLayout="scroll">
            <Column field="firstName" header="First Name" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="surname" header="Surname" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="passengerID" header="Passenger ID" sortable ></Column>
            <Column field="status" header="Status" sortable ></Column>
            <Column field="tagIDs" header="Scanned Tags" body={scannedTagsTemplate} sortable ></Column>
            <Column field="passport_barcode" header="Passport Barcode" sortable ></Column>
            <Column field="passport_status" header="Passport Status" body={passportStatusTemplate} sortable ></Column>
            <Column body={actionBodyTemplate}></Column>
        </DataTable>
    </div>;
}

export default PassengersGrid;