import { React, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';

import { classNames } from 'primereact/utils';
import { getFirestore, doc, app, storage, db } from '../../firebaseConfig';
import { nanoid } from 'nanoid'
import { add, update, getTrip } from './module';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import LuggageGrid from './LuggageGrid';

import { useLuggageStore } from '../../store';

import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";

const Luggage = () => {
    //trip id passed in via route
    let { tripid, passengerid } = useParams();
    const [trip, setTrip] = useState();
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Trips', url: `/trips` }]);
    const homeCrumb = { icon: 'pi pi-home', url: '/' }
    useEffect(() => {
        if (trip && trip.title) {
            setBreadcrumbs([...breadcrumbs, { label: trip.title }, { label: 'Passengers' }])
        }
    }, [trip])
    let emptyLuggage = {
        tripID: tripid,
        passengerID: passengerid,
        id: nanoid(10),
        status: 'No Tag',
        tagID: '',
        imageURL: ''
    };

    useEffect(() => {

        // declare the async data fetching function
        const fetchData = async () => {
            // get the data from the api
            const data = await getTrip(tripid)
            // set state with the result
            setTrip(data);
        }
        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, [tripid])
    // Pull through associated Passenger ID
    const [passenger, passengerLoading, passengerError] = useDocumentOnce(
        doc(getFirestore(app), 'passengers', passengerid),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    // Store state
    const setLuggage = useLuggageStore((state) => state.setLuggage);
    const setImageURL = useLuggageStore((state) => state.setImageURL);
    const luggage = useLuggageStore((state) => state.luggage);
    const imageURL = useLuggageStore((state) => state.imageURL);
    console.log('luggage: ', luggage)

    // Local state
    const [dialog, setDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [percent, setPercent] = useState(0);
    const [image, setImage] = useState(null); // Image File

    const toast = useRef(null)
    const luggageStatus = ['No Tag', 'Waiting For Checkpoint', 'Scanned', 'In Active', 'Temporary Inactive', 'Remain In Hold', 'Temp In Hold']
    const openNew = () => {
        setLuggage(emptyLuggage);
        setDialog(true);
    }
    const openEdit = (rowData) => {
        const fireData = { ...rowData }
        fireData.ref = rowData.ref //flag to indicate this is an update
        console.log("🚀 ~ file: Luggage.js:91 ~ openEdit ~ fireData:", fireData)
        if (fireData.imageURL) {
            getDownloadURL(ref(storage, fireData.imageURL))
                .then((url) => {
                    console.log("🚀 ~ file: uggage.js:95 ~ openEdit ~ url", url)
                    setImageURL(url)
                })
        }
        setLuggage(fireData);
        setDialog(true);
    }
    const hideDialog = () => {
        setDialog(false);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _luggage = { ...luggage };
        _luggage[`${name}`] = val;
        setLuggage(_luggage);
    }
    const saveLuggage = () => {
        if (image) {
            handleUpload();
        } else {
            saveLuggageData("");
        }
    }
    const handleUpload = () => {
        const file = image
        const storageRef = ref(storage, `/files/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                    setImageURL(url)
                    saveLuggageData(url)
                });
            }
        );
    }

    const imgStyle = {
        maxWidth: '100%',
      };
      
    const saveLuggageData = async (imageURL) => {
        // flag to display validation errors
        setSubmitted(true);
        // if no errors hide dialog
        setDialog(false);
        // Create copy of luggage
        const _luggage = { ...luggage, tripReference: trip.reference, passengerName: passenger.data().firstName + ' ' + passenger.data().surname, tripID: tripid, passengerID: passengerid, imageURL: imageURL };

        if (luggage.ref) {
            await update(luggage.ref, _luggage)

            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'luggage Updated', life: 3000 });
        } else {
            await add(_luggage)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'luggage Created', life: 3000 });
        }
        setLuggage(emptyLuggage);

    }


    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveLuggage} />
        </>
    );
    return (

        <div className="grid ">
            <BreadCrumb model={breadcrumbs} home={homeCrumb} />
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {<LuggageGrid onEdit={openEdit} tripID={tripid} passengerID={passengerid} passenger={passenger} trip={trip} onNew={openNew}></LuggageGrid>}
                </div>
            </div>

            <Dialog visible={dialog} style={{ width: '450px' }} header={trip && "Luggage Details - " + trip.title} modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="status">Status</label>
                    <Dropdown value={luggage.status} options={luggageStatus} onChange={(e) => onInputChange(e, 'status')} placeholder="Select a Status" />
                </div>
                <div className="field ">
                    <label htmlFor="tagID">Tag ID</label>
                    <InputText id="tagID" value={luggage.tagID} onChange={(e) => onInputChange(e, 'tagID')} />
                </div>
                <div className="field ">
                    <label htmlFor="imageURL">Image</label>
                    <img style={imgStyle} src={imageURL} alt="luggage" />
                </div>

            </Dialog>
        </div>
    );
}



export default Luggage;
