import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { streamLuggageByTrip } from '../../firebaseConfig';
const PassengersTable = () => {
    let { tripID } = useParams();

    const [luggageList, setLuggageList] = useState([]);
    const [totalBagsScanned, setTotalBagsScanned] = useState(0);
    const [totalBagsWaiting, setTotalBagsWaiting] = useState(0);

    // Stream Passenger Data for this Trip
    useEffect(() => {
        const unsubscribe = streamLuggageByTrip(tripID,
            (querySnapshot) => {
                const luggageList = querySnapshot.docs.map(docSnapshot => {
                    const luggageData = docSnapshot.data()
                    const ref = docSnapshot.ref
                    return { ...luggageData }
                });
                // Loop over list and group by passengerName
                const newArray = []
                let totalBagsScanned = 0
                let totalBagsWaiting = 0
                for (let i = 0; i < luggageList.length; i++) {
                    let scanned = 0
                    let waiting = 0
                    if (luggageList[i].status === 'Scanned') {
                        totalBagsScanned += 1
                        scanned = 1
                    }
                    if (luggageList[i].status === 'Waiting For Scan') {
                        totalBagsWaiting += 1
                        waiting = 1
                    }
                    let foundIndex = newArray.findIndex((obj) => obj.passengerName === luggageList[i].passengerName)
                    if (foundIndex === -1) {
                        newArray.push({ passengerName: luggageList[i].passengerName, luggageCount: 1, scanned: scanned, waiting: waiting })
                    } else {
                        newArray[foundIndex].luggageCount += scanned + waiting
                        newArray[foundIndex].scanned += scanned
                        newArray[foundIndex].waiting += waiting
                    }
                }

                setTotalBagsScanned(totalBagsScanned)
                setTotalBagsWaiting(totalBagsWaiting)
                //sort newArray by passengerName
                // need to split passengerName into first and last name using space as delimiter
                // then sort by last name, then first name
                newArray.forEach((obj) => {
                    let nameArray = obj.passengerName.split(' ')
                    obj.lastName = nameArray[nameArray.length - 1]
                    obj.firstName = nameArray[0]
                })

                newArray.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
                setLuggageList(newArray);
            },
            (error) => { console.log(error) }
        );
        // need to unsubscribe to prevent memory leaks
        return unsubscribe;
    }, [setLuggageList, tripID]);

    // Stream Passenger Data for this Trip

    return (<div>
        <div className="flex flex-row flex-wrap card-container ">
            <div className='flex align-items-center justify-content-center w-10rem h-2rem bg-blue-500 font-bold text-white border-round m-2'>Total Scanned: {totalBagsScanned}</div>
            <div className="flex align-items-center justify-content-center w-10rem h-2rem bg-blue-500 font-bold text-white border-round m-2">Total Waiting: {totalBagsWaiting}</div>
            <div className="flex align-items-center justify-content-center w-10rem h-2rem bg-blue-500 font-bold text-white border-round m-2">Total: {totalBagsScanned + totalBagsWaiting}</div>

        </div>
        <DataTable value={luggageList} tableStyle={{ minWidth: '50rem' }}>

            <Column field="passengerName" header="Name"></Column>

            <Column field="luggageCount" header="Total Luggage"></Column>
            <Column field="scanned" header="Scanned"></Column>
            <Column field="waiting" header="Waiting"></Column>
        </DataTable>


    </div>)
}

export default PassengersTable;