import { React, useState, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';

import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { addTrip, updateTrip } from './module';
import { nanoid } from 'nanoid'
import TripDataGrid from "./TripDataGrid"



const Trips = () => {

    let emptyTrip = {
        status: 'Not Started',
        title: '',
        reference: '',
        departureDate: '',
        returnDate: '',
        access: ['hello@bartelings.com'],
        id: nanoid(10)
    };
    const tripStatus = ['Not Started', 'Passenger Check In', 'Trip Started', 'Finished']

    // Display Trip Dialog
    const [tripDialog, setTripDialog] = useState(false);

    // Trip Data
    const [trip, setTrip] = useState(emptyTrip);
    // Has been submitted for form validation
    const [submitted, setSubmitted] = useState(false);
    // If editing store sequence for comparison
    const [oldSequence, setOldSequence] = useState(0);
    // Display toast information
    const toast = useRef(null)

    const homeCrumb = { icon: 'pi pi-home', url: '/' }
    const breadCrumb = [
        { label: 'Trips', url: `/trips` },
    ];
    const users = [
        'hello@bartelings.com',
        'trip-a@bartelings.com',
        'trip-b@bartelings.com',
        'demo@bartelings.com',
    ]

    const openNew = () => {
        setTripDialog(true);
    }
    // Use dialog but edit existing trip
    const openEdit = (rowData) => {
        const fireData = { ...rowData }
        fireData.ref = rowData.ref //flag to indicate this is an update
        fireData.departureDate = fireData.departureDate.toDate()
        fireData.returnDate = fireData.returnDate.toDate()
        setOldSequence(rowData.sequence)
        setTrip(fireData);
        setTripDialog(true);
    }
    const hideDialog = () => {
        setTripDialog(false);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _trip = { ...trip };
        _trip[`${name}`] = val;
        setTrip(_trip);
    }

    const onDateChange = (e, name) => {
        const val = e.value || new Date();
        let _trip = { ...trip };
        _trip[`${name}`] = val;
        setTrip(_trip);
    }

    const saveTrip = async () => {
        setSubmitted(true);
        // If trip.access does not contain hello@bartelings then add it
        if (!trip.access.includes('hello@bartelings.com')) {
            trip.access.push('hello@bartelings.com')
        }
        if (trip.ref) {
            await updateTrip(trip.ref, trip)
            setTripDialog(false);
            setTrip(emptyTrip);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trip Updated', life: 3000 });
        } else if (trip.departureDate && trip.returnDate) {
            // need to see if the sequence has changed - if it has we need to move all the other checkpoints
            await addTrip(trip)
            setTripDialog(false);
            setTrip(emptyTrip);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trip Created', life: 3000 });
        }

    }
    const tripDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveTrip} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <BreadCrumb model={breadCrumb} home={homeCrumb} />
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <TripDataGrid onEdit={openEdit} onNew={openNew}></TripDataGrid>
                </div>
            </div>

            <Dialog visible={tripDialog} style={{ width: '450px' }} header="Trip Details" modal className="p-fluid" footer={tripDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="title">Title</label>
                    <InputText id="title" value={trip.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !trip.title })} />
                    {submitted && !trip.title && <small className="p-invalid">Title is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="reference">Reference</label>
                    <InputText id="reference" value={trip.reference} onChange={(e) => onInputChange(e, 'reference')} required className={classNames({ 'p-invalid': submitted && !trip.reference })} />
                    {submitted && !trip.reference && <small className="p-invalid">reference is required.</small>}
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="departureDate">Departure Date</label>
                        <Calendar id="departureDate" dateFormat="dd/mm/yy" value={trip.departureDate}
                            onChange={(e) => onDateChange(e, 'departureDate')}
                            className={classNames({ 'p-invalid': submitted && !trip.departureDate })}
                        ></Calendar>
                        {submitted && !trip.departureDate && <small className="p-invalid">Departure date is required.</small>}

                    </div>
                    <div className="field col">
                        <label htmlFor="returnDate">Return Date</label>
                        <Calendar id="returnDate" dateFormat="dd/mm/yy" value={trip.returnDate}
                            onChange={(e) => onDateChange(e, 'returnDate')}
                            className={classNames({ 'p-invalid': submitted && !trip.departureDate })}
                        ></Calendar>
                        {submitted && !trip.returnDate && <small className="p-invalid">Return date is required.</small>}
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="status">Status</label>
                    <Dropdown value={trip.status} options={tripStatus} onChange={(e) => onInputChange(e, 'status')} placeholder="Select a Status" />

                </div>
                <div className="field">
                    <label htmlFor="access">Access</label>
                    <MultiSelect value={trip.access} onChange={(e) => onInputChange(e, 'access')} options={users}
                        placeholder="Select Users" maxSelectedLabels={3} />
                </div>
            </Dialog>


        </div>
    );
}



export default Trips;
