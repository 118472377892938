import { React, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dropdown } from 'primereact/dropdown';

import { classNames } from 'primereact/utils';
import { streamCheckpoints } from '../../firebaseConfig';

import { nanoid } from 'nanoid'
import { add, update, getTrip } from './module';
import CheckpointGrid from "./CheckpointGrid"


const Checkpoints = () => {
    //trip id passed in via route - maybe
    let { id } = useParams() || { id: '' };
    const [trip, setTrip] = useState();
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Trips', url: `/trips` }]);
    const [checkpoints, setCheckpoints] = useState([]);
    const checkpointStatus = ['Not Active', 'Active', 'Finished'];


    useEffect(() => {

        // declare the async data fetching function
        const fetchData = async () => {
            // get the data from the api
            const data = await getTrip(id)
            // set state with the result
            setTrip(data);
        }
        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, [id])

    // Stream checkpoint Data
    useEffect(() => {
        const unsubscribe = streamCheckpoints(id,
            (querySnapshot) => {
                const checkpoints = querySnapshot.docs.map(docSnapshot => {
                    const checkData = docSnapshot.data()
                    const ref = docSnapshot.ref
                    return { ref, ...checkData }
                });
                setCheckpoints(checkpoints);
            },
            (error) => { console.log(error) }
        );
        // need to unsubscribe to prevent memory leaks
        return unsubscribe;
    }, [setCheckpoints, id]);

    useEffect(() => {
        if (trip && trip.title) {
            setBreadcrumbs([...breadcrumbs, { label: trip.title }, { label: 'Checkpoints' }])
        }
    }, [trip])

    const homeCrumb = { icon: 'pi pi-home', url: '/' }


    let emptyCheckpoint = {
        cityCode: '',
        sequence: 0,
        action: '',
        place: '',
        status: 'Not Active',
        tripID: id,
        tripReference: '',
        id: nanoid(10)
    };


    // Local state
    const [dialog, setDialog] = useState(false);
    const [checkpoint, setCheckpoint] = useState(emptyCheckpoint);
    const [submitted, setSubmitted] = useState(false);

    const toast = useRef(null)

    const openNew = () => {
        setDialog(true);
    }
    const openEdit = (rowData) => {
        setCheckpoint(rowData);
        setDialog(true);
    }
    const hideDialog = () => {
        setDialog(false);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _checkpoint = { ...checkpoint };
        _checkpoint[`${name}`] = val;
        setCheckpoint(_checkpoint);
    }
    const onInputNumberChange = (e, name) => {

        let val = (e.target && e.target.value) || '';
        // if val has a . then append 0 to the end
        if (val.includes('.')) {
            val = val + '0'
        }
        console.log(val)
        // convert to float
        const intVal = new Number(parseFloat(val)).toFixed(2);
        console.log(intVal)
        let _checkpoint = { ...checkpoint };
        _checkpoint[`${name}`] = intVal;
        console.log(_checkpoint)
        setCheckpoint(_checkpoint);
    }
    const saveCheckpoint = async () => {
        // flag to display validation errors
        setSubmitted(true);
        // if no errors hide dialog
        setDialog(false);
        // Create copy of checkpoint
        const _checkpoint = { ...checkpoint, tripReference: trip.reference }

        if (checkpoint.ref) {
            // make sure sequence is a number
            _checkpoint.sequence = parseFloat(_checkpoint.sequence)

            await update(checkpoint.ref, _checkpoint)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Checkpoint Updated', life: 3000 });
        } else {
            // New So make it the last in the sequence
            _checkpoint.sequence = checkpoints.length + 1
            await add(_checkpoint)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Checkpoint Created', life: 3000 });
        }
        setCheckpoint(emptyCheckpoint);

    }

    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCheckpoint} />
        </>
    );

    return (

        <div className="grid ">
            <BreadCrumb model={breadcrumbs} home={homeCrumb} />
            <div className="col-12">

                <div className="card">
                    <Toast ref={toast} />
                    <CheckpointGrid onEdit={openEdit} trip={trip} checkpoints={checkpoints} onSetCheckpoints={setCheckpoints} onNew={openNew}></CheckpointGrid>
                </div>
            </div>

            <Dialog visible={dialog} style={{ width: '450px' }} header="Checkpoint Details" modal className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="sequence">Sequence</label>
                    <InputText id="sequence" value={checkpoint.sequence} onChange={(e) => onInputChange(e, 'sequence')} required className={classNames({ 'p-invalid': submitted && !checkpoint.sequence })} />
                    {submitted && !checkpoint.sequence && <small className="p-invalid">Sequence is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="action">Action</label>
                    <InputText id="action" value={checkpoint.action} onChange={(e) => onInputChange(e, 'action')} required className={classNames({ 'p-invalid': submitted && !checkpoint.action })} />
                    {submitted && !checkpoint.action && <small className="p-invalid">Action is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="cityCode">City Code</label>
                    <InputText id="cityCode" value={checkpoint.cityCode} onChange={(e) => onInputChange(e, 'cityCode')} required className={classNames({ 'p-invalid': submitted && !checkpoint.cityCode })} />
                    {submitted && !checkpoint.cityCode && <small className="p-invalid">City Code is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="place">Place</label>
                    <InputText id="place" value={checkpoint.place} onChange={(e) => onInputChange(e, 'place')} />

                </div>
                <div className="field">
                    <label htmlFor="status">Status</label>
                    <Dropdown value={checkpoint.status} options={checkpointStatus} onChange={(e) => onInputChange(e, 'status')} placeholder="Select a Status" />

                </div>
            </Dialog>
        </div>
    );
}



export default Checkpoints;
