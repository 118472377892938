import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

import AppTopBar from '../AppTopbar';
import AppFooter from '../AppFooter';
import AppSubmenu from '../AppSubmenu';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../App.scss';



const App = () => {

    // Security
    const { user, logout } = UserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (e) {
            console.log(e.message);
        }
    };
    const [rotateMenuButton, setRotateMenuButton] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const menu = [
        {
            label: 'Menu', icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Trips', icon: 'pi pi-fw pi-compass', to: '/trips' },
                { label: 'Login', icon: 'pi pi-fw pi-file-o', to: '/login', visible: !user },
                { label: 'Logout', icon: 'pi pi-fw pi-file-o', command: () => { handleLogout() }, visible: user !== null },
            ]
        }
    ];
    // Rotate top button in the app bar when clicked
    const onMenuButtonClick = (event) => {
        setRotateMenuButton(!rotateMenuButton);
        if (isDesktop()) {
            // Toggle the side menu
            setStaticMenuDesktopInactive(!staticMenuDesktopInactive);
        }
        else {
            // Toggle the side menu - mobile
            setStaticMenuMobileActive(!staticMenuMobileActive);
        }
        event.preventDefault();
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    let containerClassName = classNames('layout-wrapper', 'menu-layout-static', {
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active': staticMenuMobileActive,
    });

    return (
        <div className={containerClassName} >

            <div className="layout-wrapper-content">
                <AppTopBar rotateMenuButton={rotateMenuButton}
                    onMenuButtonClick={onMenuButtonClick} ></AppTopBar>

                <div className="layout-menu-container" >
                    <div className="menu-scroll-content">
                        {/*Side Menu*/}

                        <AppSubmenu className="layout-menu layout-main-menu clearfix" items={menu} root />
                    </div>
                    {(user && user.email) && <div className="flex justify-content-center flex-wrap">
                        <div className="inline-block p-2 bg-blue-500 font-bold text-white border-round m-2 text-center">
                            <div><i className='pi pi-user' style={{ 'fontSize': '2em' }}></i></div>
                            <div>{user.email}</div>
                        </div>
                    </div>}
                </div>

                <div className="layout-main">


                    <Outlet />
                </div>
                <AppFooter />
            </div>
        </div>
    );
}

export default App;
