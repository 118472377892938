
import { doc, db, serverTimestamp, setDoc, updateDoc } from '../../firebaseConfig';
const addTrip = async (tripData) => {

    try {
        await setDoc(doc(db, "trips", tripData.id), { created: serverTimestamp(), ...tripData });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}

const updateTrip = async (ref, tripData) => {
    try {
        await updateDoc(ref, { ...tripData });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}

export { addTrip, updateTrip }