import { React, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { update, deleteDocument } from './module';
import DeleteConfirm from '../DeleteConfirm';
import useDeleteStore from '../../store';
const CheckpointGrid = ({ onEdit, onSetCheckpoints, checkpoints, onNew, trip }) => {
    const [checkpoint, setCheckpoint] = useState({});
    const toggleDeleteDialog = useDeleteStore((state) => state.toggleDeleteDialog);
    const setDeleteItem = useDeleteStore((state) => state.setDeleteItem);
    const setDeleteComponent = useDeleteStore((state) => state.setDeleteComponent);
    const deleteResult = useDeleteStore((state) => state.deleteResult);
    const deleteComponent = useDeleteStore((state) => state.deleteComponent);

    // Listen for a delete state change
    useEffect(() => {
        if (deleteComponent === 'checkpoint' && deleteResult) {
            deleteDocument(checkpoint.ref)
        }
    }, [deleteResult])
    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Luggage Checkpoints</h5>
            {trip && <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3">
                    <span>{trip.title}</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Depart: {trip.departureDate.toDate().toLocaleDateString()}</span>
                </div>
                <div className="flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Return: {trip.returnDate.toDate().toLocaleDateString()}</span>
                </div>
            </div>
            }
            <div className="flex">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
                <Button label="New Checkpoint" icon="pi pi-plus" className="p-button-success p-mr-2 ml-2" onClick={() => onNew()} />

            </div>
        </div>
    );
    const onRowReorder = (e) => {
        // Loop over e.value and update each document in firebase
        // with the new order
        e.value.forEach((checkpoint, index) => { update(checkpoint.ref, { ...checkpoint, sequence: index + 1 }) })
        onSetCheckpoints(e.value);
        //toast.current.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
    }
    const deleteDoc = (checkpoint) => {
        // Local State
        setCheckpoint(checkpoint);
        // Delete State
        setDeleteComponent('checkpoint')
        setDeleteItem(`${checkpoint.cityCode} - ${checkpoint.action}`);
        // Show Dialog
        toggleDeleteDialog()
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(e) => { onEdit(rowData) }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => deleteDoc(rowData)} />
            </div>
        );
    }

    const autoscanTemplate = (rowData) => {
        if (rowData && rowData.autoScan === true) {
            return "YES"
        }
        return "NO"
    }

    return <div>
        <DeleteConfirm />
        <DataTable value={checkpoints}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            reorderableRows onRowReorder={onRowReorder}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Checkpoints"
            globalFilter={globalFilter} emptyMessage="No Checkpoints found." header={header} responsiveLayout="scroll">
            <Column rowReorder style={{ width: '3em' }} />
            <Column field="sequence" header="Sequence" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

            <Column field="cityCode" header="City Code" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="action" header="Action" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="status" header="Status" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} ></Column>
            <Column field="place" header="Place" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="autoScan" header="Autoscan" sortable body={autoscanTemplate} ></Column>
            <Column field="reason" header="Reason" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="scanCount" header="Scanned Tags" sortable ></Column>

            <Column headerStyle={{ width: '14%', minWidth: '10rem' }} body={actionBodyTemplate}></Column>
        </DataTable>
    </div>;
}

export default CheckpointGrid;