import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import Checkpoints from "./components/checkpoints/Checkpoints";
import Trips from "./components/trips/Trips";
import Passengers from "./components/passengers/Passengers";
import PassengersTable from "./components/passengers/PassengersTable";

import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Luggage from "./components/luggage/Luggage";

function App() {

    return (
        <div className="app">
            <AuthContextProvider>
                <Router>
                    <Routes>
                        <Route exact path="/" element={<Layout />}>
                            <Route path="/" element={<Navigate to="/trips" />} />
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/trips" element={<ProtectedRoute><Trips /></ProtectedRoute>} />
                            <Route path="/luggage-checkpoints/:id" element={<ProtectedRoute><Checkpoints /></ProtectedRoute>} />
                            <Route path="/luggage-checkpoints" element={<ProtectedRoute><Checkpoints /></ProtectedRoute>} />
                            <Route path="/passengers/:id" element={<ProtectedRoute><Passengers /></ProtectedRoute>} />
                            <Route path="/luggage/:tripid/:passengerid" element={<ProtectedRoute><Luggage /></ProtectedRoute>} />
                        </Route>
                        <Route path="/passengers-table/:tripID" element={<PassengersTable />} />

                    </Routes>
                </Router>
            </AuthContextProvider>
        </div>
    );
}

export default App;